/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
    --black : #000000;
    --modal_header_bg : 137, 16, 34;
    --modal_bg : rgb(51, 0, 0);
    --hexadecimal_yomp_red : #BB021E;
    --rgb_yomp_red : 187, 2, 30;
    --hexadecimal_black_yomp : rgba(0,0,0, 0.4);
    --temporal_bg_color: slateblue;
}

p, span, label, h6 {
    color: white;
}

span {
    font-weight: bold;
}

MenuComponent {
    z-index: 1;
    position: fixed;
    width: 100%;
}

HomeComponent {
    background-image: url('assets/background/png/primary.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: grid;
    align-items: end;
}

/* #region Background navigation components. */
YompMobileStepsComponent {
    background-image: url('assets/background/png/yomp_mobile_steps.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: grid;
    align-items: end;
}

CommissionsSimulatorComponent {
    background-image: url('assets/background/png/commissions-simulator.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: grid;
    align-items: end;
}

RegisterFormComponent {
    background-image: url('assets/background/png/register_form.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: grid;
    align-items: end;
}
/* #endregion */

/* #region Background transicion views */
BusinessGrowthComponent {
    background-image: url('assets/background/png/hazCrecer.png');
}

SuccessStoriesComponent, YompServicesComponent {
    background-image: url('assets/background/jpg/historias_éxito.jpg');
}

YompMobileComponent {
    background-image: url('assets/background/png/yomp_mobile.png');
}

YompPremiumComponent {
    background-image: url('assets/background/png/yomp_premium.png');
}

UsComponent {
    background-image: url('assets/background/jpg/nosotros.jpg');
}

FrequentQuestionsComponent {
    background-image: url('assets/background/png/frequent-questions.png');
}

/* #endregion  */

/* #region Container components */
.container-component {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

HomeComponent > .container-component {
    grid-template-rows: 10% 40% 50%;
}

YompMobileStepsComponent > .container-component {
    grid-template-rows: 10% 20% 60% 10%;
}

CommissionsSimulatorComponent > .container-component {
    grid-template-rows: 10% 30% 5% 40% 15%;
}

RegisterFormComponent > .container-component {
    grid-template-rows: 8% 50% 42%;
}

MenuComponent.container-component {
    height: 100%;
    grid-template-rows: 10%;
    grid-template-columns: 100%;
    align-items: end;
}

BusinessGrowthComponent.container-component {
    grid-template-rows: 10% 40% 28% 22%;
    align-items: end;
}

SuccessStoriesComponent.container-component {
    grid-template-rows: 10% 15% 45% 5% 20%;
}

YompMobileComponent.container-component {
    grid-template-rows: 10% 35% 20% 25% 10%;
}

YompPremiumComponent.container-component {
    grid-template-rows: 10% 35% 25% 15% 15%;
}

UsComponent.container-component {
    grid-template-rows: 10% 15% 70% 5%;
}

YompServicesComponent.container-component {
    grid-template-rows: 10% 15% 10% 60% 5%;
}

FrequentQuestionsComponent.container-component {
    grid-template-rows: 15% 30% 35% 20%;
}

/* #endregion */

.container-title {
    grid-row: 2/3;
    grid-column: 3/8;
    align-self: center;
}

.container-title > p {
    color: white;
    font-family: 'Lato';
    font-size: 740%;
    font-weight: 900;
    line-height: 100%;
    margin: 0%;
}

.container-detail {
    background-color: rgba(var(--rgb_yomp_red), .4);
    display: grid;
}

.items-service {
    grid-column: 2/3;
}

.items-service .go {
    display: flex;
}

.item-service {
    display: flex;
    align-items: center;
}

.item-service > p {
    color: white;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 2vw;
    margin-bottom: 0%;
}

li {
    color: white;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 0.9vw;
    margin-bottom: 0%;
}

.items-service > .item-service > img {
    width: 35px;
    height: 35px;
    margin-right: 4%;
}

.item-service > p {
    line-height: 130%;
}

.container-subtitle {
    color: white;
    font-family: 'Lato';
    font-size: 400%;
    font-weight: 900;
    margin-top: 5%;
    margin-bottom: 5%;
    line-height: 85%;
}

.go {
    align-self: self-end;
    display: flex;
    margin-bottom: 0%;
    margin-right: 10%;
    padding-bottom: 5%;
    padding-left: 30%;
    justify-self: right;
}

.go > p {
    font-size: 150%;
    margin-right: 5%;
    margin-bottom: 2%;
    font-weight: 300;
    color: white;
    white-space: nowrap;
}

.go > img {
    width: 40px;
    height: 40px;
}

.paragraph {
    color: white;
    font-family: 'Lato';
    font-size: 1vw;
    font-weight: 300;
    line-height: 125%;
    left: 5vh;
    top: -6vh;
    text-align: justify;
    margin-bottom: 5%;
}

.url {
    font-weight: 700;
}

.url:hover {
    text-decoration: underline white;
}

/* #region Yomp Services */

.container-platform-detail {
    grid-row: 3/4;
    grid-column: 3/6;
    align-self: center;
}

.container-platform-detail > p {
    font-size: 1.75vw;
}

.container-platform-image {
    grid-column: 6/9;
    grid-row: 2/5;
}

.container-platform-image img {
    height: 100%;
}

.container-commission-button {
    display: grid;
    grid-row: 4/5;
    grid-column: 3/5;
    align-self: center;
    justify-content: center;
}

.button-white-transparent {
    border-radius: 20%/60%;
    background-color: rgba(250, 250, 250, 0.1);
    border: 1px solid white;
    font-size: 1.2vw;
    width: 18vw;
    height: 12vh;
    color: white;
}

.button-white-transparent:hover {
    background-color: rgba(250, 250, 250, 0.5);
}

.span-btn-commission {
    font-size: 1.8vw;
}

.container-download-now {
    grid-row: 5/6;
    grid-column: 3/5;
    display: flex;
}

.container-download-now > span {
    font-size: 1.3vw;
    white-space: nowrap;
    margin-right: 10%;
}

.container-download-now > img {
    width: 35%;
    height: 50%;
}

.container-text-arrow {
    grid-row: 5/6;
    grid-column: 8/10;
    display: flex;
    justify-content: center;
    align-self: center;
}

.container-text-arrow > p {
    white-space: nowrap;
    margin-right: 2%;
    font-size: 1vw;
}

.container-text-arrow > img {
    width: 30px;
    height: 30px;
}
/* #endregion */

.maxContent {
    width: max-content;
}

select {
    padding: 4.2% 5%;
    border-radius: 10px;
    width: 80%;
    text-align: center;
}

input {
    border-radius: 10px;
    width: 80%;
    padding: 3.5% 5%;
}

.flex-form {
    background-color: rgba(var(--rgb_yomp_red), .4);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2% 2%;
}

.item-form {
    flex-basis: 26%;
    font-size: 80%;
    margin-bottom: 1%;
    height: 20%;
}

.item-form > .form-control {
    font-size: 100%;
}

.container-double-columns {
    flex-basis: 58%;
}

@media screen and (max-height: 589px) {
    
    .item-form {
        flex-basis: 30%;
        font-size: 60%;
        margin-bottom: 1%;
        height: 20%;
    }
    
    .item-form > .form-control {
        font-size: 90%;
    }

    .container-double-columns {
        flex-basis: 62%;
    }

}